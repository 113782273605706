"use client";

import { Box, IconButton, Lazy, Typography } from "components";
import { useEffect, useState, useRef, useCallback } from "react";
import classnames from "classnames";
import { useMedia } from "hooks";
import { getById } from "utils";
import gsap from "gsap";

function HomeFeatures({ website }) {
  const { position, handleNext, handleBefore, carousel, disabled } =
    useFeatures();

  return (
    <Box className="BupDiscover-slider">
      <span className="BupDiscover-control">
        <IconButton
          icon="navigate_before"
          onClick={() => handleBefore()}
          disabled={position === 0}
        />
      </span>
      <span className="BupDiscover-control">
        <IconButton
          icon="navigate_next"
          onClick={() => handleNext()}
          disabled={disabled}
        />
      </span>
      <Box className="BupDiscover-carousel">
        <Box ref={carousel} component="ol">
          {website.get("home.features.cards")?.map((card, index) => (
            <Box key={index} id={"slide-" + index} component="li">
              <Lazy
                className="BupDiscover-image"
                src={`assets/features-${index}.webp`}
              >
                <Box className="BupDiscover-filter">
                  <Typography variant="h4">{card.title}</Typography>
                  <Typography
                    className={classnames({ "Bup-soon": !card.available })}
                    variant="span"
                  >
                    {
                      website.get("home.features.availability")?.[
                        card.available
                      ]
                    }
                  </Typography>
                </Box>
              </Lazy>
              <Box className="BupDiscover-caption">
                <Typography variant="p">{card.description}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
  53;
}

function useFeatures() {
  const [position, setPosition] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const slideWidth = useRef(0);
  const carousel = useRef();
  const screen = useMedia();
  const size = 5;

  const handleNext = useCallback(() => {
    const maxPosition = Math.max(screen.width - 80, 425) / slideWidth.current;
    const maxWidth = slideWidth.current * (Math.floor(maxPosition) - size);
    const x = Math.max(position - slideWidth.current, maxWidth);

    gsap.to(carousel.current, { x, duration: 0.3 });
    setPosition(x);
  }, [position, screen.width, size]);

  const handleBefore = useCallback(() => {
    const x = Math.min(position + slideWidth.current, 0);

    gsap.to(carousel.current, { x, duration: 0.3 });
    setPosition(x);
  }, [position, screen.width, size]);

  useEffect(() => {
    const slide = getById("slide-0");
    slideWidth.current = slide ? slide.offsetWidth + 10 : 0;

    const maxPosition = Math.max(screen.width - 80, 425) / slideWidth.current;
    const maxWidth = slideWidth.current * (Math.floor(maxPosition) - size + 1);

    setDisabled(position < maxWidth);
  }, [position, screen.width]);

  return { position, handleNext, handleBefore, carousel, disabled };
}

export default HomeFeatures;
