"use client";

import { Box, Button, Icon, Stack, Typography } from "components";
import { withContent } from "contexts/content";
import classnames from "classnames";
import Gallery from "./Gallery";

function HomeHeader({ redirect, screen, website }) {
  return (
    <>
      <Stack className="BupHome-header" component="header">
        <Typography variant="h1">{website.get("home.header.title")}</Typography>
        <Typography variant="p">
          {website.get("home.header.description")}
        </Typography>
        <Stack>
          <Button
            id="BH-login"
            className="BupButton-wide only-xs"
            color="inherit"
            onClick={redirect.login}
            size="large"
            variant="text"
          >
            {website.get("home.header.loginText")}
          </Button>
          <Button
            id="BH-welcome"
            className={classnames("BupButton-wide", {
              "BupButton-dark": screen.mobile,
            })}
            color="inherit"
            onClick={redirect.register}
            size="large"
            variant="text"
          >
            {website.get("home.header.buttonText")}
          </Button>
        </Stack>
        <Box className="Bup-scroll">
          <Icon name="keyboard_arrow_down" />
        </Box>
      </Stack>
      <Gallery />
    </>
  );
}

export default withContent(HomeHeader);
