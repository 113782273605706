"use client";

import { useEffect, useState } from "react";
import { Box, Curve, Image } from "components";
import { useMedia } from "hooks";
import { obj } from "utils";

function HomeGallery() {
  const { cols, rows, gallery } = useGallery({
    minCols: 8,
    rows: 3,
    total: 32,
  });

  return (
    <Box id="home-gallery" className="BupHome-gallery" component="section">
      <Curve />
      {obj.generateArray(rows + 1).map((grid, row) => (
        <Box key={row} className={`gr-${grid}`}>
          {gallery.slice(row * cols, (row + 1) * cols).map((index) => (
            <Image
              key={index}
              alt={`pic-${index}`}
              src={`gallery/${index}.webp`}
              height={84.375}
              width={150}
            />
          ))}
        </Box>
      ))}
      <Curve />
    </Box>
  );
}

function useGallery({ minCols, rows, total }) {
  const [cols, setColumns] = useState(minCols);
  const [gallery, setGallery] = useState([]);
  const screen = useMedia();

  useEffect(() => {
    setGallery(obj.shuffleArray(obj.generateArray(total)));
  }, [total]);

  useEffect(() => {
    setColumns(Math.min(Math.ceil(screen.width / 160), minCols));
  }, [screen.width, minCols]);

  return { cols, rows, gallery };
}

export default HomeGallery;
